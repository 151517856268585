<template>
  <div class="companyRes-card">
  <div class="title">企业认证信息</div>
  <div>
    <p>企业名称</p>
    <p>{{val.companyName}}</p>
  </div>
  <div>
    <p>信用代码</p>
    <p>{{val.socialCreditCode}}</p>
  </div>
    <div>
      <p>法定代表人</p>
      <p>{{val.legalPersonName}}</p>
    </div>
    <div>
      <p>身份证号</p>
      <p>{{val.legalPersonCitizenIdNo | capitalize}}</p>
    </div>
    <div>
      <p>提交时间</p>
      <p>{{val.updateTime}}</p>
    </div>

  </div>
</template>

<script>
export default {
  name: 'companyRes-card',
  props:['val'],
}
</script>

<style scoped lang="scss">
.companyRes-card{
  width: 343px;
  background: #FFFFFF;
  border: 1px solid #D4D4D4;
  border-radius: 4px;
  padding: 0 16px;
  margin-top: 24px;
  margin-bottom: 16px;
  >.title{
    height: 51px;
    font-size: 14px;

    line-height: 51px;
    color: #262626;
    border-bottom: #EEEEEE solid 1px;
    margin-bottom: 12px;
  }
  &>:not(:first-child){
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    margin-bottom: 12px;
    &>:first-child{
      font-size: 14px;
      color: #999999;
     width: 75px;
      white-space: nowrap;
    }
    &>:last-child{
      font-size: 14px;
      color: #262626;
      max-width: 221px;
      white-space:normal;
      word-break:break-all;
    }
  }
}
</style>
