<template>
<div class="company-result">
  <div class="content">
    <div class="personal_result-box">
      <div class="personal_icon ">
        <div>
          <img :src="result_text.img" alt="">
        </div>
      </div>
      <p class="personal-title">{{result_text.title}}</p>

      <p class="personal-info" v-if="!success && companyRes">驳回原因：{{companyRes.verifyFailedReason}}</p>

      <p class="personal-info" v-if="!comSuccess">驳回原因：{{company.joinCompanyDenialReason}}</p>

      <companyInfo-card :val="company" v-if="company"></companyInfo-card>

      <companyRes-card :val="companyRes" v-if="companyRes"></companyRes-card>



    </div>
  </div>


  <div class="btn">
    <van-button v-if="associationBool" class="btn-left" color="#EA0B06" plain  block @click="association">重新绑定</van-button>
    <van-button v-if="verifiedBool" class="btn-left" color="#EA0B06" plain  block @click="verifiedCom">修改企业认证</van-button>
  </div>

</div>
</template>

<script>
import { getH5CurrentCompany , getInformation} from "@/api/res-company";
import companyInfo_card from '@/page/verified/components/companyInfo_card'
import companyRes_Card from '@/page/verified/components/companyRes_card'
import { Toast } from 'vant'
export default {
  name: 'company_result',
  data () {
    return {
      company:'', //关联信息

      companyRes:'', //认证信息

      associationBool:false,

      verifiedBool:false,

      denialReason:'',

      result_text:{
        img:'',
        title:"",
      },

      success:true,

      comSuccess:true,

      err_data: {
        img: require('@/assets/img/personal_result1.png'),
        title:'企业认证失败',
      },
      while_data:{
        img: require('@/assets/img/personal_result2.png'),
        title:'企业认证中',
      },
      res_data:{
        img: require('@/assets/img/personal_result.png'),
        title:'企业认证成功',
      }
    }
  },
  components:{
    'companyInfo-card':companyInfo_card,
    'companyRes-card':companyRes_Card
  },

  computed:{
    type:function (){
      return this.$route.query.type
    },
  },
   created() {

    this.loadCompany()
     /* getInformation().then((data)=>{
       this.denialReason=data.denialReason
     }) */
  },

  mounted () {

    if(this.type==='association') document.title = '企业绑定'
    if(this.type==='verified') document.title = '企业认证'
  },
  methods:{


   async loadCompany(){

      Toast.loading({
        message: '加载中',
        forbidClick: true,
      });

     await getH5CurrentCompany().then((company) => {
       this.denialReason=company.verifyFailedReason
        if(this.type==='association'){
          this.assciation_company(company)
        }else if(this.type==='verified'){
          this.verifiedBool=true
          this.verified_company(company)
        }
      });
      Toast.clear()

    },


    assciation_company(company){
      if(company.joinCompanyVerifyStatus==='3') this.associationBool=true

      this.company = company;

      let map=new Map([['2',this.res_data],['1',this.while_data],['3',this.err_data]])
      let textMap={'1':'绑定审核中','2':'绑定成功','3':'绑定失败'}
      map.forEach((item,key)=>{
        if(this.company.joinCompanyVerifyStatus==='3'){

          this.comSuccess=false

        }
        if(this.company.joinCompanyVerifyStatus===key){
          this.result_text=item
          this.result_text.title=textMap[this.company.joinCompanyVerifyStatus]
        }
      })
    },

    verified_company(company){

      this.companyRes = company;

      if(company.verifyStatus==='4') this.success=false

      let map={'2':this.while_data,'3':this.res_data,'4':this.err_data}

      this.result_text=map[company.verifyStatus]


    },


    verifiedCom(){
      this.$router.push('/verified/certification_company')
    },



    association(){
       this.$router.push('/verified/company')
    }


  },
}
</script>

<style scoped lang="scss">
.company-result{
  width: 100vw;
  min-height: 100vh;
  //position: fixed;
  background: #F6F6F6;
  padding-top: 72px;
  padding-bottom: 50px;
}
.content{
  width: 100vw;
  padding: 0 16px;
  .personal_icon{
    height: 78px;
    margin-bottom: 24px;
    >div{
      margin: 0 auto;
      max-width: 78px;
      max-height: 78px;
      >img{
        width: 100%;
        height: 100%;
      }
    }
  }

  .personal-title{
    text-align: center;
    font-size: 24px;
    //font-weight: 500;
    line-height: 30px;
    color: #262626;
  }

  .personal-info{
    margin: 12px 16px 0;
    font-size: 14px;
    //font-weight: 500;
    line-height: 21px;
    color: #999999;
  }
}
.btn{
  width: 100vw;
  padding: 0 16px;
}

</style>
